import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import NotFound from './views/NotFound'
import Unauthorized from './views/Unauthorized'
import store from '@/store'
import security from '@/security'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: true },
    component: Login,
  },
  {
    path: '/prepaid_ai',
    name: 'Prepaid Activation Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "prepaidActivationIncentive" */ '@/views/PrepaidActivationIncentive'),
  },
  {
    path: '/black_ai',
    name: 'Black Activation Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackActivationIncentive" */ '@/views/BlackActivationIncentive'),
  },
  {
    path: '/onefamily_ai',
    name: 'ONEfamily AI',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "oneFamilyAI" */ '@/views/OneFamilyAI'),
  },
  {
    path: '/activity',
    name: 'Activities',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "activity" */ '@/views/Activity'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile'),
  },
  {
    path: '/kpi',
    name: 'Key Performance Index',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "qrcode" */ '@/views/Kpi'),
  },
  {
    path: '/kpisld',
    name: 'SLD Key Performance Index',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "qrcode" */ '@/views/KpiSLD'),
  },
  {
    path: '/qrcode',
    name: 'Dealer Affiliate QR Code',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "qrcode" */ '@/views/QrCode'),
  },
  {
    path: '/top_performance',
    name: 'Top Performance',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "topPerformance" */ '@/views/TopPerformance'),
  },
  {
    path: '/monthly_top_performance',
    name: 'Monthly Top Performance',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "monthlyTopPerformance" */ '@/views/MonthlyTopPerformance'),
  },
  {
    path: '/daily_top_performance',
    name: 'Daily Top Performance',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dailyTopPerformance" */ '@/views/DailyTopPerformance'),
  },
  {
    path: '/prepaidRegistration',
    name: 'Register Prepaid Subscriber',
    meta: { requiresAuth: true, roles: ['sales'] },
    component: () => import(/* webpackChunkName: "prepaidRegistration" */ '@/views/PrepaidRegistration'),
  },
  {
    path: '/black_cobp',
    name: 'COBP to Black',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackCobp" */ '@/views/BlackCobp'),
  },
  {
    path: '/black_kits',
    name: 'Black Kits',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackKits" */ '@/views/BlackKits'),
  },
  {
    path: '/black_pay_bill',
    name: 'Pay Black bill',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackPayBill" */ '@/views/BlackPayBill'),
  },
  {
    path: '/black_cop',
    name: 'Change Black plan',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackChangeOfPlan" */ '@/views/BlackChangeOfPlan'),
  },
  {
    path: '/black_pin_kits',
    name: 'Black Pin Kits',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackPinKits" */ '@/views/BlackPinKits'),
  },
  {
    path: '/black_pin_transactions',
    name: 'Black Pin Transactions',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackPinTransactions" */ '@/views/BlackPinTransactions'),
  },
  {
    path: '/black_purchase_pin',
    name: 'Purchase Black Pin',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackPurchasePin" */ '@/views/BlackPurchasePin'),
  },
  {
    path: '/black_transfer_pin',
    name: 'Transfer Black Pin',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackTransferPin" */ '@/views/BlackTransferPin'),
  },
  {
    path: '/support/family',
    name: 'Family',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "Family" */ '@/views/dmt/Family'),
    props: true,
  },
  {
    path: '/support/family/:id',
    name: 'Family Plan',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "Family" */ '@/views/dmt/Family'),
    props: true,
  },
  {
    path: '/support/mysiswa',
    name: 'My Siswa',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "My Siswa" */ '@/views/dmt/MySiswa'),
    props: true,
  },
  {
    path: '/support/mysiswa/:id',
    name: 'My Siswa Plan',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "My Siswa" */ '@/views/dmt/MySiswa'),
    props: true,
  },
  {
    path: '/black_register_subscriber',
    name: 'Register Black Subscriber',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackRegisterSubscriber" */ '@/views/BlackRegisterSubscriber'),
  },
  {
    path: '/mysiswa_register_subscriber/:id',
    name: 'MySiswa Resubmit Mnp',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "mySiswaRegisterSubscriber" */ '@/views/MySiswaRegisterSubscriber'),
    props: true,
  },
  {
    path: '/family_register_subscriber/:id',
    name: 'Family Resubmit Mnp',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "familyRegisterSubscriber" */ '@/views/FamilyRegisterSubscriber'),
    props: true,
  },
  {
    path: '/black_register_subscriber/:id',
    name: 'Resubmit Mnp',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackRegisterSubscriber" */ '@/views/BlackRegisterSubscriber'),
    props: true,
  },
  {
    path: '/prepaid_resubmit_mnp/:id',
    name: 'Prepaid Resubmit Mnp',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "prepaidResubmitMnp" */ '@/views/PrepaidResubmitMnp'),
    props: true,
  },
  {
    path: '/black_upgrade_dreg',
    name: 'Upgrade DREG plan to Black',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackUpgradeDreg" */ '@/views/BlackUpgradeDreg'),
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "campaigns" */ '@/views/Campaigns'),
  },
  {
    path: '/campaign_semua_menang',
    name: 'Semua Menang Campaign',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "campaignSemuaMenang" */ '@/views/CampaignSemuaMenang'),
  },
  {
    path: '/user_sld',
    name: 'SLD Brochure',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "userSLD" */ '@/views/UserSLD'),
    props: true,
  },
  // {
  //   path: '/campaign_senang_win',
  //   name: 'Senang Win Campaign',
  //   meta: { requiresAuth: true, roles: ['dealer'] },
  //   component: () => import(/* webpackChunkName: "campaignSenangWin" */ '@/views/CampaignSenangWin'),
  // },
  {
    path: '/campaign_tactical',
    name: 'Tactical Campaign',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "campaignTactical" */ '@/views/CampaignTactical'),
  },
  {
    path: '/campaign_mega',
    name: 'Campaign Mega',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "campaignMega" */ '@/views/CampaignMega'),
  },
  {
    path: '/jombantu',
    name: 'Jom Bantu',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "jombantu" */ '@/views/JomBantu'),
  },
  {
    path: '/jombantu_upload_simcard',
    name: 'Jom Bantu Upload Simpack',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "jombantuuploadsimcard" */ '@/views/JomBantuUploadSimcard'),
  },
  {
    path: '/jombantu_list',
    name: 'Jom Bantu Student Listing',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "jombantulist" */ '@/views/JomBantuList'),
  },
  {
    path: '/jombantu_doc',
    name: 'Jom Bantu Documents',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "jombantulistdoc" */ '@/views/JomBantuDoc'),
  },
  {
    path: '/jombantu_cert',
    name: 'Jom Bantu Certificate',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "jombantucert" */ '@/views/JomBantuCert'),
  },
  {
    path: '/certificates',
    name: 'Certificates',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "certificates" */ '@/views/Certificates'),
  },
  {
    path: '/downloads',
    name: 'Downloads',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "downloads" */ '@/views/Downloads'),
  },
  {
    path: '/upload_simcard',
    name: 'Upload SIM Pack',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "uploadSimcard" */ '@/views/UploadSimcard'),
  },
  {
    path: '/pickup_order',
    name: 'Pickup Order',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "pickupOrder" */ '@/views/PickupOrder'),
  },
  {
    path: '/mnp_noncelcom',
    name: 'Mnp Non-Celcom',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "mnpNonCelcom" */ '@/views/MnpNonCelcom'),
  },
  {
    path: '/unl35Campaign',
    name: 'UNL35 Campaign',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "unl35Campaign" */ '@/views/Unl35Campaign'),
  },
  {
    path: '/dealer_kits',
    name: 'Dealer Kits',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dealerKits" */ '@/views/DealerKits'),
  },
  {
    path: '/dealership_kits',
    name: 'Dealership',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dealershipKits" */ '@/views/DealershipKits'),
  },
  {
    path: '/dealership_appoint',
    name: 'Appoint Dealer',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dealershipAppoint" */ '@/views/DealershipAppoint'),
  },
  {
    path: '/dealership_subscription',
    name: 'Annual Subscription',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dealershipSubscription" */ '@/views/DealershipSubscription'),
  },
  {
    path: '/dealership_transfer_pin',
    name: 'Transfer Dealership PIN',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dealershipTransferPin" */ '@/views/DealershipTransferPin'),
  },
  {
    path: '/areadealer',
    name: 'Area Dealer',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "areadealer" */ '@/views/AreaDealer'),
  },
  {
    path: '/dealership_pin_record',
    name: 'PIN Record',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dealershipPinRecord" */ '@/views/DealershipPinRecord'),
  },
  {
    path: '/support/subscription_list',
    name: 'Subscription List',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "subscriptionList" */ '@/views/dmt/SubscriptionList'),
  },
  {
    path: '/support/pushup_user_list',
    name: 'Pushup User List',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "subscriptionList" */ '@/views/dmt/PushupUserList'),
  },
  {
    path: '/support/dealer_terminated_list',
    name: 'Dealer demoted/terminated List',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "dealerTerminatedList" */ '@/views/dmt/DealerTerminatedList'),
  },
  {
    path: '/support/simpack_list',
    name: 'Simpack List',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "uploadSimpackList" */ '@/views/dmt/UploadSimpackList'),
  },
  {
    path: '/support/user_statistics',
    name: 'User Statistics',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "userStatistics" */ '@/views/dmt/UserStatistics'),
  },
  {
    path: '/support/annual_user_statistics',
    name: 'Annual User Statistics',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "annualuserStatistics" */ '@/views/dmt/AnnualUserStatistics'),
  },
  {
    path: '/support/monthly_user_statistics',
    name: 'Monthly User Statistics',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "monthlyUserStatistics" */ '@/views/dmt/MonthlyUserStatistics'),
  },
  {
    path: '/support/weekly_user_statistics',
    name: 'Weekly User Statistics',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "weeklyUserStatistics" */ '@/views/dmt/WeeklyUserStatistics'),
  },
  {
    path: '/support/monthly_sld_statistics',
    name: 'SLD Statistics',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "monthlySldStatistics" */ '@/views/dmt/MonthlySldStatistics'),
  },
  {
    path: '/support/mnp_request_list',
    name: 'Mnp Status',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "MnpRequestList" */ '@/views/dmt/MnpRequestList'),
  },
  {
    path: '/support/mnp_imsi',
    name: 'Mnp Imsi',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "MnpImsi" */ '@/views/dmt/MnpImsi'),
  },
  {
    path: '/support/mnp_reject',
    name: 'Mnp Reject',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "MnpImsi" */ '@/views/dmt/MnpReject'),
  },
  {
    path: '/support/topup_history',
    name: 'Topup History',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "TopupHistory" */ '@/views/dmt/TopupHistory'),
  },
  {
    path: '/support/dealer_news',
    name: 'Dealer News',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "DealerNews" */ '@/views/dmt/DealerNews'),
  },
  {
    path: '/support/daily_activation_ranking',
    name: 'Daily Activation Ranking',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "dailyActivationRanking" */ '@/views/dmt/DailyActivationRanking'),
  },
  {
    path: '/support/car_bonanza',
    name: 'Car Bonanza',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "carBonanza" */ '@/views/dmt/CarBonanza'),
  },
  {
    path: '/support/user_registration',
    name: 'User Registration',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "carBonanza" */ '@/views/dmt/UserRegistration'),
  },
  {
    path: '/support/dealer_registration',
    name: 'Dealer Registration',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "dealerRegistration" */ '@/views/dmt/DealerRegistration'),
  },
  {
    path: '/support/dealer_registration_by_day',
    name: 'Dealer Registration By Day',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "dealerRegistrationByDay" */ '@/views/dmt/DealerRegistrationByDay'),
  },
  {
    path: '/support/active_record',
    name: 'Active Record',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "activeRecord" */ '@/views/dmt/ActiveRecord'),
  },
  {
    path: '/support',
    name: 'DMT Dashboard',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import('@/views/dmt/Dashboard'),
  },
  {
    path: '/support/dmt_kits',
    name: 'DMT Kits',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "dmtKits" */ '@/views/dmt/DmtKits'),
  },
  {
    path: '/support/dmt_reports',
    name: 'DMT Reports',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "dmtReports" */ '@/views/dmt/DmtReports'),
  },
  {
    path: '/support/ad_achievement',
    name: 'AD Achievement',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "adAchievement" */ '@/views/dmt/AdAchievement'),
  },
  {
    path: '/support/md_periodic_performance',
    name: 'MD Periodic Reports',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "mdPeriodicReport" */ '@/views/dmt/MdPeriodicPerformance'),
  },
  {
    path: '/support/ad_periodic_performance',
    name: 'AD Periodic Reports',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "adPeriodicReport" */ '@/views/dmt/AdPeriodicPerformance'),
  },
  {
    path: '/support/set_introducer',
    name: 'Set Introducer',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "setIntroducer" */ '@/views/dmt/SetIntroducer'),
  },
  {
    path: '/support/mnp',
    name: 'Mnp',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "Mnp" */ '@/views/dmt/Mnp'),
  },
  {
    path: '/support/reject_mnp',
    name: 'Reject MNP',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "rejectMnp" */ '@/views/dmt/RejectMnp'),
  },
  {
    path: '/support/activate_user',
    name: 'Activate User',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "activateUser" */ '@/views/dmt/ActivateUser'),
  },
  {
    path: '/ecash_kits',
    name: 'E-recharge Kits',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "ecashKits" */ '@/views/EcashKits'),
  },
  {
    path: '/ecash_record',
    name: 'E-recharge Record',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "ecashRecord" */ '@/views/EcashRecord'),
  },
  {
    path: '/ecash_transfer',
    name: 'Transfer E-recharge',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "ecashTransfer" */ '@/views/EcashTransfer'),
  },
  {
    path: '/ecash_kits_demoted',
    name: 'E-recharge Kits Demoted',
    meta: { requiresAuth: true, roles: ['subscriber'] },
    component: () => import(/* webpackChunkName: "ecashKitsDemoted" */ '@/views/EcashKitsDemoted'),
  },
  {
    path: '/ecash_record_demoted',
    name: 'E-recharge Record Demoted',
    meta: { requiresAuth: true, roles: ['subscriber'] },
    component: () => import(/* webpackChunkName: "ecashRecordDemoted" */ '@/views/EcashRecordDemoted'),
  },
  {
    path: '/ecash_transfer_demoted',
    name: 'Transfer E-recharge Demoted',
    meta: { requiresAuth: true, roles: ['subscriber'] },
    component: () => import(/* webpackChunkName: "ecashTransferDemoted" */ '@/views/EcashTransferDemoted'),
  },
  /*
  {
    path: '/erecharge_donate_tkkr',
    name: 'Donate to TKKR SAG',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import('@/views/ErechargeDonateTkkr')
  },
  */
  {
    path: '/finance',
    name: 'Finance Dashboard',
    meta: { requiresAuth: true, roles: ['management', 'finance'] },
    component: () => import('@/views/finance/Dashboard'),
  },
  {
    path: '/finance/finance_kits',
    name: 'Finance',
    meta: { requiresAuth: true, roles: ['management', 'finance'] },
    component: () => import(/* webpackChunkName: "financeKits" */ '@/views/finance/FinanceKits'),
  },
  {
    path: '/finance/statistics',
    name: 'Statistics',
    meta: { requiresAuth: true, roles: ['management', 'finance'] },
    component: () => import(/* webpackChunkName: "statistics" */ '@/views/finance/Statistics'),
  },
  {
    path: '/finance/topup_statistics',
    name: 'Topup Statistics',
    meta: { requiresAuth: true, roles: ['management', 'finance'] },
    component: () => import(/* webpackChunkName: "topupStat" */ '@/views/finance/TopupStat'),
  },
  {
    path: '/finance/purchase_orders',
    name: 'Purchase Orders',
    meta: { requiresAuth: true, roles: ['finance'] },
    component: () => import(/* webpackChunkName: "purchaseOrders" */ '@/views/finance/PurchaseOrders'),
  },
  {
    path: '/finance/black_pin_orders',
    name: 'Black Pin Orders',
    meta: { requiresAuth: true, roles: ['finance'] },
    component: () => import(/* webpackChunkName: "blackPinOrders" */ '@/views/finance/BlackPinOrders'),
  },
  {
    path: '/snm',
    name: 'Snm Dashboard',
    meta: { requiresAuth: true, roles: ['snm'] },
    component: () => import('@/views/snm/Dashboard'),
  },
  {
    path: '/snm/snm_kits',
    name: 'Snm',
    meta: { requiresAuth: true, roles: ['snm'] },
    component: () => import(/* webpackChunkName: "snmKits" */ '@/views/snm/SnmKits'),
  },
  {
    path: '/snm/change_rank',
    name: 'Change Rank',
    meta: { requiresAuth: true, roles: ['snm'] },
    component: () => import(/* webpackChunkName: "changeRank" */ '@/views/snm/ChangeRank'),
  },
  {
    path: '/incentives',
    name: 'Incentives',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "incentives" */ '@/views/Incentives'),
  },
  {
    path: '/annual_royalty_incentive',
    name: 'Annual Royalty Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "annualRoyaltyIncentive" */ '@/views/AnnualRoyaltyIncentive'),
  },
  {
    path: '/monthly_royalty_incentive',
    name: 'Monthly Royalty Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "monthlyRoyaltyIncentive" */ '@/views/MonthlyRoyaltyIncentive'),
  },
  {
    path: '/daily_royalty_incentive',
    name: 'Daily Royalty Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dailyRoyaltyIncentive" */ '@/views/DailyRoyaltyIncentive'),
  },
  {
    path: '/monthly_royalty_incentive_sld',
    name: 'Monthly SLD Royalty Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "monthlySLDRoyaltyIncentive" */ '@/views/MonthlySLDRoyaltyIncentive'),
  },
  {
    path: '/daily_royalty_incentive_sld',
    name: 'Daily SLD Royalty Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dailySLDRoyaltyIncentive" */ '@/views/DailySLDRoyaltyIncentive'),
  },
  {
    path: '/recruitment_incentive',
    name: 'Dealer Appointment Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dealerRecruitmentIncentive" */ '@/views/DealerRecruitmentIncentive'),
  },
  {
    path: '/income',
    name: 'Dealer Income',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "income" */ '@/views/Income'),
  },
  {
    path: '/fc_incentives',
    name: 'FC Incentives',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "fcIncentives" */ '@/views/FcIncentives'),
  },
  {
    path: '/mysiswa_special_incentives',
    name: 'MySiswa Special Incentives',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "mysiswaSpecialIncentive" */ '@/views/MySiswaSpecialIncentive'),
  },
  {
    path: '/subscriber_reload',
    name: 'Subscriber Reload',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "subscriberReload" */ '@/views/SubscriberReload'),
  },
  {
    path: '/royalty_incentives',
    name: 'Royalty Incentive Kits',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "royaltyIncentiveKits" */ '@/views/RoyaltyIncentiveKits'),
  },
  {
    path: '/royalty_incentives_sld',
    name: 'SLD Royalty Incentive Kits',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "royaltyIncentiveKitsSLD" */ '@/views/RoyaltyIncentiveKitsSLD'),
  },
  {
    path: '/incentive_ssp',
    name: 'SSP Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "sspIncentive" */ '@/views/SspIncentive'),
  },
  {
    path: '/incentive_wawa',
    name: 'WAWA Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "wawaIncentive" */ '@/views/WawaIncentive'),
  },
  {
    path: '/incentive_perpaduan',
    name: 'Perpaduan Incentive',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "perpaduanIncentive" */ '@/views/PerpaduanIncentive'),
  },
  {
    path: '/lead',
    name: 'Learning Education & Development',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "lead" */ '@/views/Lead'),
  },
  {
    path: '/dcp_cert',
    name: 'Dealer Certification Program',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dcpCert" */ '@/views/DcpCert'),
  },
  {
    path: '/credit_hours',
    name: 'Credit Hours',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "creditHours" */ '@/views/CreditHours'),
  },
  {
    path: '/training_materials',
    name: 'Training Materials',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "trainingMaterials" */ '@/views/TrainingMaterials'),
  },
  {
    path: '/rsvp_lead',
    name: 'RSVP Lead Class',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "rsvpLead" */ '@/views/RsvpLead'),
  },
  {
    path: '/lead_menu',
    name: 'Lead Menu',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "leadMenu" */ '@/views/LeadMenu'),
  },
  {
    path: '/onexapp_install',
    name: 'Onexapp Install',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "onexappInstall" */ '@/views/OnexappInstall'),
  },
  {
    path: '/dealer_guide',
    name: 'Dealer Guide',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "dealerGuide" */ '@/views/DealerGuide'),
  },
  {
    path: '/subscriber_guide',
    name: 'Subscriber Guide',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "subscriberGuide" */ '@/views/SubscriberGuide'),
  },
  {
    path: '/marketing_tool',
    name: 'Marketing Tools',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "marketingTool" */ '@/views/MarketingTool'),
  },
  {
    path: '/shariah_matter',
    name: 'Shariah Matters',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "shariahMatter" */ '@/views/ShariahMatter'),
  },
  {
    path: '/contractAD',
    name: 'Contract AD',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "contractAD" */ '@/views/ContractAD'),
  },
  {
    path: '/adRegion',
    name: 'Ad Region',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "adRegion" */ '@/views/ADRegion'),
  },
  {
    path: '/mdState',
    name: 'Md State',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "mdState" */ '@/views/MDState'),
  },
  {
    path: '/business_registeration',
    name: 'Business Registration',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "businessRegisteration" */ '@/views/BusinessRegisteration'),
  },
  {
    path: '/ownership_transfer',
    name: 'Ownership Transfer',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "ownershipTransfer" */ '@/views/OwnershipTransfer'),
  },
  {
    path: '/cp58_statement',
    name: 'CP58 Statement',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "cp58Statement" */ '@/views/Cp58Statement'),
  },
  {
    path: '/support/ssm_verification',
    name: 'SSM Verification',
    meta: { requiresAuth: true, resourceRoles: { roles: ['impersonation'], resource: 'realm-management' } },
    component: () => import(/* webpackChunkName: "SsmVerification" */ '@/views/dmt/SsmVerification'),
  },
  {
    path: '/technical',
    name: 'Technical Dashboard',
    meta: { requiresAuth: true, roles: ['devop'] },
    component: () => import('@/views/technical/Dashboard'),
  },
  {
    path: '/technical/technical_kits',
    name: 'Technical Kits',
    meta: { requiresAuth: true, roles: ['devop'] },
    component: () => import(/* webpackChunkName: "technicalKits" */ '@/views/technical/TechnicalKits'),
  },
  {
    path: '/technical/change_dreg',
    name: 'Change DREG',
    meta: { requiresAuth: true, roles: ['devop'] },
    component: () => import(/* webpackChunkName: "changeDreg" */ '@/views/technical/ChangeDreg'),
  },
  {
    path: '/technical/credit_adjustment',
    name: 'Credit Adjustment',
    meta: { requiresAuth: true, roles: ['devop'] },
    component: () => import(/* webpackChunkName: "creditAdjustment" */ '@/views/technical/CreditAdjustment'),
  },
  {
    path: '/technical/reactivate_dealer',
    name: 'Reactivate Dealer',
    meta: { requiresAuth: true, roles: ['devop'] },
    component: () => import(/* webpackChunkName: "creditAdjustment" */ '@/views/technical/ReactivateDealer'),
  },
  {
    path: '/technical/revoke_basic_dealer',
    name: 'Revoke Basic Dealer',
    meta: { requiresAuth: true, roles: ['devop'] },
    component: () => import(/* webpackChunkName: "revokeBasicDealer" */ '@/views/technical/RevokeBasicDealer'),
  },
  {
    path: '/management',
    name: 'Management Dashboard',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import('@/views/management/Dashboard'),
  },
  {
    path: '/management/management_kits',
    name: 'Management Report',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "managementKits" */ '@/views/management/ManagementKits'),
  },
  {
    path: '/management/black_pin_usage',
    name: 'Black Pin Usages',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "blackPinUsage" */ '@/views/management/BlackPinUsage'),
  },
  {
    path: '/management/daily_view',
    name: 'Daily Excerpt',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "dailyView" */ '@/views/management/DailyView'),
  },
  {
    path: '/management/monthly_view',
    name: 'Monthly Excerpt',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "monthlyView" */ '@/views/management/MonthlyView'),
  },
  {
    path: '/management/reimbursement_view',
    name: 'Reimbursement Excerpt',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "reimbursementview" */ '@/views/management/ReimbursementView'),
  },
  {
    path: '/management/family_subline_reimburse',
    name: 'Subline Reimbursement Report',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "familysublinereimburse" */ '@/views/management/FamilySublineReimburse'),
  },
  {
    path: '/management/october_campaign_reimburse',
    name: 'Campaign Reimbursement Report',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "octobercampaignreimburse" */ '@/views/management/OctoberCampaignReimburse'),
  },
  {
    path: '/management/basic_pin_mysiswa_reimburse',
    name: 'Basic Pin (MySISWA) Reimbursement Report',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "basicpinmysiswareimburse" */ '@/views/management/BasicPinMysiswaReimburse'),
  },
  {
    path: '/management/daily_activation',
    name: 'Daily Activation',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "dailyActivation" */ '@/views/management/DailyActivation'),
  },
  {
    path: '/management/prihatin_stat',
    name: 'Prihatin Statistics',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "prihatinStat" */ '@/views/management/PrihatinStat'),
  },
  {
    path: '/management/monthly_activation',
    name: 'Monthly Activation',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "monthlyActivation" */ '@/views/management/MonthlyActivation'),
  },
  {
    path: '/management/monthly_dealer_kpi',
    name: 'Monthly Dealer KPI',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "monthlyDealerKpi" */ '@/views/management/MonthlyDealerKpi'),
  },
  {
    path: '/management/floating_erecharge',
    name: 'Floating Erecharge',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "floatingErecharge" */ '@/views/management/FloatingErecharge'),
  },
  {
    path: '/management/activations_by_state',
    name: 'Activations By State',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "activationByState" */ '@/views/management/ActivationByState'),
  },
  {
    path: '/management/daily_recruitment',
    name: 'Daily Recruitment',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "dailyRecruitment" */ '@/views/management/DailyRecruitment'),
  },
  {
    path: '/management/daily_prepaid',
    name: 'Daily Prepaid',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "dailyPrepaid" */ '@/views/management/DailyPrepaid'),
  },
  {
    path: '/management/daily_fc_activation',
    name: 'Daily FC Activation',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "dailyFcActivation" */ '@/views/management/DailyFcActivation'),
  },
  {
    path: '/management/daily_fc_distribution',
    name: 'Daily FC Plan Distribution',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/* webpackChunkName: "dailyActivationFc" */ '@/views/management/DailyFcDistribution'),
  },
  /*
  {
    path: '/management/super_dealer',
    name: 'Super Dealer',
    meta: { requiresAuth: true, roles: ['management'] },
    component: () => import(/ webpackChunkName: "superDealer" / '@/views/management/SuperDealer'),
  },
  */
  {
    path: '/operation',
    name: 'Operation Dashboard',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import('@/views/operation/Dashboard'),
  },
  {
    path: '/operation/operation_kits',
    name: 'Operation Kits',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "operationKits" */ '@/views/operation/OperationKits'),
  },
  {
    path: '/operation/corporate_kits',
    name: 'Corporate',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "corporateKits" */ '@/views/operation/CorporateKits'),
  },
  {
    path: '/operation/corporate_dealer',
    name: 'Corporate Dealer',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "corporateDealer" */ '@/views/operation/CorporateDealer'),
  },
  {
    path: '/operation/corporate_payment',
    name: 'Corporate Payment',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "corporatePayment" */ '@/views/operation/CorporatePayment'),
  },
  {
    path: '/operation/reserved_history',
    name: 'Reserved History',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "reservedHistory" */ '@/views/operation/ReservedHistory'),
  },
  {
    path: '/operation/transfer_hierarchy',
    name: 'Transfer Hierarchy',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "transferHierarchy" */ '@/views/operation/TransferHierarchy'),
  },
  {
    path: '/operation/topup_staff',
    name: 'Topup Staff',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "topupStaff" */ '@/views/operation/TopupStaff'),
  },
  {
    path: '/operation/jom_bantu_kits',
    name: 'Jom Bantu Kits',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "jombantukits" */ '@/views/operation/JomBantuKits'),
  },
  {
    path: '/operation/jb_update_details',
    name: 'Update Details',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "jbupdatedetails" */ '@/views/operation/JbUpdateDetails'),
  },
  {
    path: '/operation/jb_list_student',
    name: 'List Students',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "jbliststudent" */ '@/views/operation/JbListStudent'),
  },
  {
    path: '/operation/ifinancing',
    name: 'iFinancing',
    meta: { requiresAuth: true, roles: ['operation'] },
    component: () => import(/* webpackChunkName: "iFinancing" */ '@/views/operation/iFinancing'),
  },
  {
    path: '/prepaid_kits',
    name: 'Prepaid Kits',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "prepaidKits" */ '@/views/PrepaidKits'),
  },
  {
    path: '/prepaid_topup',
    name: 'Prepaid Topup',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "prepaidTopup" */ '@/views/PrepaidTopup'),
  },
  {
    path: '/subscriber_group',
    name: 'Subscriber Group',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "subscriberGroup" */ '@/views/SubscriberGroup'),
  },
  {
    path: '/subscriber_performance',
    name: 'Subscriber Group Performance',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "subscriberPerformance" */ '@/views/SubscriberPerformance'),
  },
  {
    path: '/subscriber_tier',
    name: 'Subscriber Tiers',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "subscriberTier" */ '@/views/SubscriberTier'),
  },
  {
    path: '/ugu',
    name: 'User Gets User',
    meta: { requiresAuth: true, roles: ['subscriber'] },
    component: () => import(/* webpackChunkName: "ugu" */ '@/views/Ugu'),
  },
  {
    path: '/ugu_list',
    name: 'UGU List',
    meta: { requiresAuth: true, roles: ['subscriber'] },
    component: () => import(/* webpackChunkName: "uguList" */ '@/views/UguList'),
  },
  {
    path: '/ugu_reloads',
    name: 'UGU Reloads',
    meta: { requiresAuth: true, roles: ['subscriber'] },
    component: () => import(/* webpackChunkName: "uguReloads" */ '@/views/UguReloads'),
  },
  {
    path: '/ugu_rewards',
    name: 'UGU Reward',
    meta: { requiresAuth: true, roles: ['subscriber'] },
    component: () => import(/* webpackChunkName: "uguReward" */ '@/views/UguReward'),
  },
  {
    path: '/group_activations',
    name: 'Group Activations',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "groupActivations" */ '@/views/GroupActivations'),
  },
  {
    path: '/fc_activations',
    name: 'FC Activations',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "fcActivations" */ '@/views/FcActivations'),
  },
  {
    path: '/xforce',
    name: 'X-Force Dashboard',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import('@/views/xforce/Dashboard'),
  },
  {
    path: '/xforce/administration',
    name: 'X-Force Administration',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "xforceAdministration" */ '@/views/xforce/Administration'),
  },
  {
    path: '/xforce/daily_report',
    name: 'X-Force Daily Report',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "xforceDailyReport" */ '@/views/xforce/DailyReport'),
  },
  {
    path: '/xforce/schedule',
    name: 'X-Force Scheduling',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "xforceSchedule" */ '@/views/xforce/Schedule'),
  },
  {
    path: '/xforce/track',
    name: 'X-Force Tracking',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "xforceTrack" */ '@/views/xforce/Track'),
  },
  {
    path: '/reload',
    name: 'Topup & Pay Bill',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "blackPayBill" */ '@/views/Reload'),
  },
  {
    path: '/reload_demoted',
    name: 'Topup & Pay Bill Demoted',
    meta: { requiresAuth: true, roles: ['subscriber'] },
    component: () => import(/* webpackChunkName: "blackPayBillDemoted" */ '@/views/ReloadDemoted'),
  },
  {
    path: '/suggestion_box',
    name: 'Suggestion Box',
    meta: { requiresAuth: true, roles: ['dealer'] },
    component: () => import(/* webpackChunkName: "suggestionBox" */ '@/views/SuggestionBox'),
  },
  {
    path: '*',
    name: 'Not Found',
    meta: { requiresAuth: true },
    component: NotFound,
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: NotFound,
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    meta: { requiresAuth: true },
    component: Unauthorized,
  },
]

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes,
})

router.beforeEach((to, from, next) => {
  const auth = store.state.security.auth

  if (to.meta.requiresAuth) {
    if (auth.authenticated) {
      if (to.meta.roles && to.meta.roles.length > 0) {
        if (security.roles(to.meta.roles)) {
          next()
        } else {
          next({ name: 'Unauthorized' })
        }
      } else if (to.meta.resourceRoles) {
        if (security.roles(to.meta.resourceRoles.roles, to.meta.resourceRoles.resource)) {
          next()
        } else {
          next({ name: 'Unauthorized' })
        }
      } else {
        next()
      }
    } else {
      security.init(next, to.meta.roles)
    }
  } else {
    if (auth.authenticated) {
      if (to.path === from.path) {
        next(false)
      } else if (to.path === '/') {
        next()
      } else {
        security.init(next, to.meta.roles)
      }
    } else {
      security.init(next, to.meta.roles, true)
    }
    /*
      console.log('to', to)
    console.log('from', from)
    // if (location.href.indexOf('#state') > -1) {
    // security.init(next, to.meta.roles)
    // } else
    if (to.path === '/') {
      if (!from.name || from.path.length > 1) {
        security.init(next, to.meta.roles)
      } else if (from.hash.indexOf('#state') > -1) {
        next(false)
      // } else if (from.path.length > 1 && from.path.indexOf('/') === 0) {
      //   security.init(next, to.meta.roles)
      } else {
        next()
      }
    } else if (to.path === from.path) {
      next(false)
    } else {
      security.init(next, to.meta.roles, true)
      next()
    }
    */
  }
})

export default router
